/**
 * pdl.js
 * @version v0.0.1
 * @license MIT
 * @copyright 2021 108nen
 * @link
 */
// import PDL_Cookie = require("./pdl-cookie");
// import PDL_Scroll = require("./pdl-scroll");
// const DEBUG_MODE: boolean = true;
var DEBUG_MODE = false;
function TRACE() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (DEBUG_MODE && window.console && typeof window.console.log !== "undefined") {
        var str = "";
        if (args.length > 0) {
            str = args.join(", ");
        }
        // tslint:disable-next-line: no-console
        console.log(str);
    }
}
// Cookie
// tslint:disable-next-line: max-classes-per-file
var PdlCookie = /** @class */ (function () {
    function PdlCookie() {
        this.domain = "";
        this.path = "/";
        this.sameSite = "Strict";
        this.expires = 30;
    }
    PdlCookie.prototype.write = function (n, v) {
        var dt = new Date();
        dt.setDate(dt.getDate() + this.expires);
        document.cookie = encodeURIComponent(n) + "=" + encodeURIComponent(v) + "; "
            + (this.domain === "" ? "" : "Domain=" + this.domain + "; ")
            + (this.path === "" ? "" : "Path=" + this.path + "; ")
            + (this.sameSite === "" ? "" : "SameSite=" + this.sameSite + "; ")
            + "Expires=" + dt.toUTCString();
    };
    PdlCookie.prototype.read = function (n) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + n.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : "";
    };
    return PdlCookie;
}());
// Smooth Scroll
// tslint:disable-next-line: max-classes-per-file
var PdlScroll = /** @class */ (function () {
    function PdlScroll() {
        var _this = this;
        var duration = 500;
        window.addEventListener("DOMContentLoaded", function () {
            var scrollTriggers = [].slice.call(document.querySelectorAll('a[href^="#"]'));
            scrollTriggers.forEach(function (scrollTrigger) {
                scrollTrigger.addEventListener("click", function (e) {
                    var href = scrollTrigger.getAttribute("href");
                    if (href) {
                        var currentPosition_1 = document.documentElement.scrollTop || document.body.scrollTop;
                        var targetElement = document.getElementById(href.replace("#", ""));
                        if (targetElement) {
                            e.preventDefault();
                            e.stopPropagation();
                            var targetPosition_1 = window.pageYOffset + targetElement.getBoundingClientRect().top;
                            var startTime_1 = performance.now();
                            var loop_1 = function (nowTime) {
                                var time = nowTime - startTime_1;
                                var normalizedTime = time / duration;
                                if (normalizedTime < 1) {
                                    window.scrollTo(0, currentPosition_1 + ((targetPosition_1 - currentPosition_1) * _this.easeInOut(normalizedTime)));
                                    requestAnimationFrame(loop_1);
                                }
                                else {
                                    window.scrollTo(0, targetPosition_1);
                                }
                            };
                            requestAnimationFrame(loop_1);
                        }
                    }
                });
            });
        });
    }
    PdlScroll.prototype.easeInOut = function (t) {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };
    return PdlScroll;
}());
// Select Theme
// tslint:disable-next-line: max-classes-per-file
var PdlTheme = /** @class */ (function () {
    function PdlTheme() {
        var nowTheme = pdlCookie.read("theme");
        if (nowTheme === "dark") {
            document.body.classList.remove("pdl-body--default");
            document.body.classList.add("pdl-body--dark");
        }
        else if (nowTheme === "light") {
            document.body.classList.remove("pdl-body--default");
            document.body.classList.add("pdl-body--light");
        }
        else {
            TRACE("default");
        }
        window.addEventListener("DOMContentLoaded", function () {
            var changeThemeTriggers = [].slice.call(document.querySelectorAll("#id-button-select-theme"));
            changeThemeTriggers.forEach(function (changeThemeTrigger) {
                changeThemeTrigger.addEventListener("mousedown", function () {
                    if (document.body.classList.contains("pdl-body--default") === true) {
                        var darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
                        var isDarkMode = darkModeMediaQuery.matches;
                        document.body.classList.remove("pdl-body--default");
                        if (isDarkMode) {
                            TRACE("default -> light");
                            document.body.classList.add("pdl-body--light");
                            pdlCookie.write("theme", "light");
                        }
                        else {
                            TRACE("default -> dark");
                            document.body.classList.add("pdl-body--dark");
                            pdlCookie.write("theme", "dark");
                        }
                    }
                    else if (document.body.classList.contains("pdl-body--dark") === true) {
                        TRACE("dark -> light");
                        document.body.classList.remove("pdl-body--dark");
                        document.body.classList.add("pdl-body--light");
                        pdlCookie.write("theme", "light");
                    }
                    else {
                        TRACE("light -> dark");
                        document.body.classList.remove("pdl-body--light");
                        document.body.classList.add("pdl-body--dark");
                        pdlCookie.write("theme", "dark");
                    }
                });
            });
        });
    }
    return PdlTheme;
}());
// Scroll
// tslint:disable-next-line: max-classes-per-file
var PdlSticky = /** @class */ (function () {
    function PdlSticky() {
    }
    PdlSticky.prototype.init = function () {
        TRACE("PdlSticky");
    };
    return PdlSticky;
}());
/*
$(function($){
    var content = $("#content");
    var sidebar = $("#sidebar");
    var sticked = $("#sticked");

    // サイドバーの位置
    var sidebar_top = sidebar.offset().top;
    // 固定するコンテンツの元々の位置
    var sticked_original_top = sticked.offset().top;
    // 固定するコンテンツの高さ
    var sticked_height = sticked.height();

    $(window).on('scroll resize', function(){ // スクロールかリサイズ時
        // 現在の位置
        var scrollTop = $(document).scrollTop();
        // メインコンテンツ最後尾
        var content_bottom = content.offset().top + content.height();

        if ((scrollTop > sticked_original_top) && (scrollTop < content_bottom - sticked_height)){
            // 現在位置が、初期位置より下で、メインコンテンツ最後尾より上なら、画面上部にサイドバーを固定
            sticked.css({'position': 'fixed',
                'top': 0,
                'width': sidebar.width()
            });
        } else if(scrollTop >= content_bottom - sticked_height){
            // 現在位置がメインコンテンツ最後尾より下なら、メインコンテンツ最後尾にサイドバーを位置させる
            sticked.css({'position': 'absolute',
                'top': content_bottom - sticked_height - sidebar_top,
                'width': sidebar.width()
            });
        } else {
            // 現在位置が初期位置より上なら、何もしない
            sticked.css({'position': 'static'});
        }
    });
});
*/
// Preload
// tslint:disable-next-line: max-classes-per-file
/*
class PdlPreload {
    constructor() {
        
    }
}*/
// Main
var pdlScroll = new PdlScroll();
var pdlCookie = new PdlCookie();
var pdlTheme = new PdlTheme();
window.onload = function (e) {
    // const pdlPreload = new PdlPreload();
    document.body.classList.remove("pdl-preload");
};
